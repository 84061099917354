import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { NerdCodeField, NerdKeyPad } from "@nerdjs/nerd-ui";
import { useDispatch, useSelector } from "react-redux";
import DateTime from "../component/dateTime";
import UserDetails from "../component/userDetails";
import { resetStatusTimer } from "../constants";
import {
  entryCodeAddNumber,
  entryCodeDeleteNumber,
  entryCodeSetNewValue,
} from "../redux/entryCodeSlice";
import { clearStatus } from "../redux/status/statusActions";
import { statusSelector } from "../redux/status/statusSelector";
import { entryCodeSelector } from "../selectors/entryCodeSelector";
import { ButtonType } from "../types";

/**
 *
 * @returns {ReactElement} A happy coding page!
 */
export function Clock() {
  //const [value, setValue] = useState("");
  const entryCode = useSelector(entryCodeSelector);
  const status = useSelector(statusSelector);
  const dispatch = useDispatch();

  const handleButtonPressed = (
    selectedNumber: number,
    buttonType: ButtonType
  ) => {
    if (buttonType === "number") {
      dispatch(entryCodeAddNumber(`${selectedNumber}`));
    } else if (buttonType === "clear") {
      {
        dispatch(clearStatus());
      }
    } else if (buttonType === "delete") {
      dispatch(entryCodeDeleteNumber());
    }
  };

  const keypad = (
    <Box
      sx={{
        width: "250px",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ mb: 2, width: 250 }}>
        <NerdCodeField
          error={false}
          TextfieldProps={{
            sx: {
              background: "#e2e2e2",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            },
            type: "password",
          }}
          length={4}
          value={entryCode}
          onChange={(newValue) => dispatch(entryCodeSetNewValue(newValue))}
        ></NerdCodeField>
      </Box>
      <NerdKeyPad handleButtonPressed={handleButtonPressed} />
    </Box>
  );

  const greetings = (
    <>
      <Typography
        variant="h3"
        fontFamily={"sans-serif"}
        color={"#9f9f9f"}
        fontSize={"3rem"}
        sx={{
          fontWeight: "bold",
          display: "flex",
          justifyContent: "center",
        }}
      >
        Welcome!
      </Typography>
      <Typography
        color={"#9f9f9f"}
        sx={{ display: "flex", justifyContent: "space-around" }}
      >
        Enter your pin to get started
      </Typography>
    </>
  );

  const clock = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: 2,
        justifyContent: "center",
      }}
    >
      <i
        style={{ fontSize: 35, marginRight: 10 }}
        className="fa-light fa-clock"
      ></i>
      <DateTime />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          visibility: status ? "visible" : "hidden",
          width: status ? "0%" : "100%",
          height: 10,
          background: "#4caf51",
          position: "absolute",
          top: 0,
          transition: status ? `width ${resetStatusTimer * 1000}ms` : "none",
        }}
      ></Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          marginLeft: 2,
        }}
      >
        {clock}
        {status ? <UserDetails /> : greetings}
      </Box>
      {keypad}
    </Box>
  );
}
