import { PostAction } from "@nerdjs/nerd-network";
import { createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { SigninPL } from "../../entities/signinPL";

export const createSigninPL = createAction(
  "service/signinPLs/createSigninPL",
  function prepare(body: { code: string }) {
    const request = new PostAction(`account/users/signinPL`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const signinPL = new SigninPL(json);
      dispatch(createSigninPLSuccess(signinPL));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const createSigninPLSuccess = createAction(
  "service/signinPLs/createSigninPLSuccess",
  function prepare(signinPL: SigninPL) {
    return {
      payload: {
        signinPL,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
