import { createSlice } from "@reduxjs/toolkit";

export const signInOTPSlice = createSlice({
  name: "signInOTP",
  initialState: {
    value: "",
  },
  reducers: {
    signInOTPAddNumber: (state, action) => {
      state.value += action.payload;
    },
    signInOTPDeleteNumber: (state) => {
      state.value = state.value.slice(0, -1);
    },
    signInOTPSetNewValue: (state, action) => {
      state.value = action.payload;
    },
    signInOTPClearValue: (state) => {
      state.value = "";
    },
  },
});

export const {
  signInOTPAddNumber,
  signInOTPDeleteNumber,
  signInOTPSetNewValue,
  signInOTPClearValue,
} = signInOTPSlice.actions;

export const signInOTPReducer = signInOTPSlice.reducer;
