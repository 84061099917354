import { createReducer } from "@reduxjs/toolkit";
import { clearStatus, getStatusByIdSuccess } from "./statusActions";
import { StatusState } from "./statusState";

function initState(): StatusState {
  return {
    status: undefined,
  };
}

export const statusReducer = createReducer(initState(), (builder) => {
  builder.addCase(getStatusByIdSuccess, (state, action) => {
    state.status = action.payload.status;
    return state;
  });
  builder.addCase(clearStatus, (state) => {
    state.status = undefined;
    return state;
  });
});
