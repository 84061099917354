import { accountKitApi, accountKitReducer } from "@nerdjs/account-kit";
import { networkMiddleware, networkReducer } from "@nerdjs/nerd-network";
import { notificationReducer } from "@nerdjs/nerd-ui";
import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { entryCodeReducer } from "./entryCodeSlice";
import { mainMiddleware } from "./mainMiddleware";
import { signInOTPReducer } from "./signInOTPSlice";
import { statusReducer } from "./status/statusReducer";

const rootReducer = combineReducers({
  accountKitState: accountKitReducer,
  notification: notificationReducer,
  statusState: statusReducer,
  entryCode: entryCodeReducer,
  signInOTP: signInOTPReducer,
  networkState: networkReducer,
  [accountKitApi.reducerPath]: accountKitApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunkMiddleware,
    mainMiddleware,
    accountKitApi.middleware,
    networkMiddleware, // Always add networkMiddleware after your api middleware
  ],
});

export type AppDispatch = typeof store.dispatch;
