import { createSelector } from "@reduxjs/toolkit";
import { Status } from "../../entities/status";
import { RootState } from "../store";

const selectStatus = (state: RootState) => state.statusState.status;

export const statusSelector = createSelector([selectStatus], (s) => {
  if (!s) return undefined;
  const ret = new Status(JSON.parse(JSON.stringify(s)));
  if (ret?.history) {
    ret.history.forEach((workday) => {
      workday.punches.sort((a, b) => {
        const d1 = new Date(a.clockTime);
        const d2 = new Date(b.clockTime);
        return d1.getTime() - d2.getTime();
      });
    });
  }
  return ret;
});
