import { Button } from "@mui/joy";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Punch } from "../entities/punch";
import { useAppSelector } from "../hooks/hooks";
import { createPunch } from "../redux/punch/punchActions";
import { statusSelector } from "../redux/status/statusSelector";

function twoDigitsMin(n: number) {
  return (n < 10 ? "0" : "") + n;
}
function PunchBar({
  punches,
  beginingOfDay,
  endOfDay,
}: {
  punches: Punch[];
  beginingOfDay: number;
  endOfDay: number;
}) {
  let timeGapInSeconds;
  if (beginingOfDay < endOfDay) {
    timeGapInSeconds = endOfDay * 3600 - beginingOfDay * 3600;
  } else {
    const temp = 24 - beginingOfDay;
    timeGapInSeconds = (temp + endOfDay) * 3600;
  }

  const timeInSeconds: number[] = [];
  const bars = [];
  let offset = 0;
  let minMargin = 0;

  punches.forEach((punch) => {
    const date = new Date(punch.clockTime);
    timeInSeconds.push(
      date.getHours() * 3600 + date.getMinutes() * 60 - beginingOfDay * 3600
    );
  });

  if (timeInSeconds.length === 1) {
    const mgLeft = (timeInSeconds[0] / timeGapInSeconds) * 100;
    bars.push(
      <Box
        key={1}
        sx={{
          background: "#2196f3",
          position: "absolute",
          width: 47,
          height: "100%",
          marginLeft: `${mgLeft}%`,
          borderBottomLeftRadius: 50,
          borderTopLeftRadius: 50,
          borderBottomRightRadius: 50,
          borderTopRightRadius: 50,
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            color={"#ffffff"}
            fontSize={"0.7rem"}
            fontWeight={"bold"}
            sx={{ marginLeft: 1 }}
          >
            {new Date(punches[0].clockTime).getHours()}:
            {twoDigitsMin(new Date(punches[0].clockTime).getMinutes())}
          </Typography>
        </Box>
      </Box>
    );
  }

  if (timeInSeconds.length >= 2) {
    const mgLeft = (timeInSeconds[0] / timeGapInSeconds) * 100;
    let sizeBar;
    if (timeInSeconds[0] < timeInSeconds[1]) {
      sizeBar =
        ((timeInSeconds[1] - timeInSeconds[0]) / timeGapInSeconds) * 100;
    } else {
      const temp = 24 * 3600 - timeInSeconds[0];
      sizeBar = ((temp + timeInSeconds[1]) / timeGapInSeconds) * 100;
    }

    bars.push(
      <Box
        key={2}
        sx={{
          background: "#2196f3",
          position: "absolute",
          width: `${sizeBar}%`,
          minWidth: 80,
          height: "100%",
          marginLeft: `${mgLeft}%`,
          borderBottomLeftRadius: 50,
          borderTopLeftRadius: 50,
          borderBottomRightRadius: timeInSeconds.length == 2 ? 50 : 0,
          borderTopRightRadius: timeInSeconds.length == 2 ? 50 : 0,
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            color={"#ffffff"}
            fontSize={"0.7rem"}
            fontWeight={"bold"}
            sx={{ marginLeft: 1 }}
          >
            {new Date(punches[0].clockTime).getHours()}:
            {twoDigitsMin(new Date(punches[0].clockTime).getMinutes())}
          </Typography>
          <Typography
            color={"#ffffff"}
            fontSize={"0.7rem"}
            fontWeight={"bold"}
            sx={{ marginRight: 1 }}
          >
            {new Date(punches[1].clockTime).getHours()}:
            {twoDigitsMin(new Date(punches[1].clockTime).getMinutes())}
          </Typography>
        </Box>
      </Box>
    );
    offset = mgLeft + sizeBar;
    minMargin = mgLeft;
  }
  if (timeInSeconds.length >= 3) {
    let sizeBar;
    if (timeInSeconds[1] < timeInSeconds[2]) {
      sizeBar =
        ((timeInSeconds[2] - timeInSeconds[1]) / timeGapInSeconds) * 100;
    } else {
      const temp = 24 * 3600 - timeInSeconds[1];
      sizeBar = ((temp + timeInSeconds[2]) / timeGapInSeconds) * 100;
    }
    bars.push(
      <Box
        key={3}
        sx={{
          background: "#bbdefb",
          position: "absolute",
          width: `${sizeBar}%`,
          height: "100%",
          marginLeft: `max(${offset}%, calc(${minMargin}% + 80px))`,
        }}
      ></Box>
    );
    offset += sizeBar;
    minMargin += sizeBar;
  }

  if (timeInSeconds.length >= 4) {
    let sizeBar;
    if (timeInSeconds[2] < timeInSeconds[3]) {
      sizeBar =
        ((timeInSeconds[3] - timeInSeconds[2]) / timeGapInSeconds) * 100;
    } else {
      const temp = 24 * 3600 - timeInSeconds[2];
      sizeBar = ((temp + timeInSeconds[3]) / timeGapInSeconds) * 100;
    }

    bars.push(
      <Box
        key={4}
        sx={{
          background: "#2196f3",
          position: "absolute",
          width: `${sizeBar}%`,
          minWidth: 80,
          height: "100%",
          marginLeft: `max(${offset}%, calc(${minMargin}% + 80px))`,
          borderBottomRightRadius: 50,
          borderTopRightRadius: 50,
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            color={"#ffffff"}
            fontSize={"0.7rem"}
            fontWeight={"bold"}
            sx={{ marginLeft: 1 }}
          >
            {new Date(punches[2].clockTime).getHours()}:
            {twoDigitsMin(new Date(punches[2].clockTime).getMinutes())}
          </Typography>
          <Typography
            color={"#ffffff"}
            fontSize={"0.7rem"}
            fontWeight={"bold"}
            sx={{ marginRight: 1 }}
          >
            {new Date(punches[3].clockTime).getHours()}:
            {twoDigitsMin(new Date(punches[3].clockTime).getMinutes())}
          </Typography>
        </Box>
      </Box>
    );
    offset += sizeBar;
  }

  return <Box sx={{ position: "relative", height: 30 }}>{bars}</Box>;
}

function PunchDay({ date }: { date: Date }) {
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 2,
        marginLeft: 2,
        width: 50,
      }}
    >
      <Typography
        sx={{
          lineHeight: "normal",
          fontFamily: "sans-serif",
          fontWeight: "bold",
        }}
      >
        {days[date.getDay()]}
      </Typography>
      <Typography
        sx={{
          color: "#2196f3",
          lineHeight: "normal",
          fontFamily: "sans-serif",
          fontSize: "0.65rem",
          fontWeight: "bold",
        }}
      >
        {twoDigitsMin(date.getMonth() + 1)}/{twoDigitsMin(date.getDate())}
      </Typography>
    </Box>
  );
}

function ClockRecords() {
  const status = useAppSelector(statusSelector);
  if (!status) return <Box />;
  const history = status.history;

  const punchesDay = [];
  const punchesBar = [];

  const beginingOfDay = status.getBeginingOfDay();
  const endOfDay = status.getEndOfDay();

  for (let i = 0; i < history.length && i < 3; i++) {
    punchesDay.push(
      <PunchDay key={i} date={new Date(history[i].date)}></PunchDay>
    );
    punchesBar.push(
      <PunchBar
        key={i}
        punches={history[i].punches}
        beginingOfDay={beginingOfDay}
        endOfDay={endOfDay}
      ></PunchBar>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        height: 230,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
        }}
      >
        {punchesDay}
      </Box>
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          left: 62,
          alignItems: "center",
        }}
      >
        <Typography>{beginingOfDay}:00</Typography>
        <Box sx={{ borderLeft: "solid 1px rgb(155,155,155)", flexGrow: 1 }} />
      </Box>

      <Box
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "space-evenly",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        {punchesBar}
      </Box>
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          right: -20,
          alignItems: "center",
        }}
      >
        <Typography sx={{ width: 40 }}>{endOfDay}:00</Typography>
        <Box sx={{ borderRight: "solid 1px rgb(155,155,155)", flexGrow: 1 }} />
      </Box>
    </Box>
  );
}

export default function UserDetails() {
  const status = useAppSelector(statusSelector);
  const dispatch = useDispatch();
  const time = new Date().getHours();
  const handleButtonClick = () => {
    const body = {
      clockTime: new Date().toISOString(),
      pin: Number(status?.clockPin),
    };

    if (status?.status === "out") {
      dispatch(createPunch(body));
    } else {
      handleClickOpen();
    }
  };

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (answer: boolean) => {
    const body = {
      clockTime: new Date().toISOString(),
      pin: Number(status?.clockPin),
      prevented: false,
    };
    if (answer) {
      body.prevented = true;
      dispatch(createPunch(body));
      setOpen(false);
    } else {
      dispatch(createPunch(body));
      setOpen(false);
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          fontWeight: "bold",
          lineHeight: "normal",
          fontFamily: "sans-serif",
        }}
      >
        {time < 12 ? "Good morning" : "Good afternoon"} {status?.firstName}!
      </Typography>
      <Typography
        sx={{
          color: "#9f9f9f",
          lineHeight: "normal",
          fontFamily: "sans-serif",
          fontWeight: "600",
        }}
      >
        These are you last 3 records:
      </Typography>
      <Box sx={{ marginTop: 2 }}>
        <ClockRecords />
      </Box>
      <Box sx={{ marginTop: 4, display: "flex", justifyContent: "center" }}>
        <Button
          variant="solid"
          style={{
            minWidth: 200,
            width: "70%",
            borderRadius: 50,
            background: status?.status === "out" ? "#4caf51" : "#f44335",
          }}
          onClick={handleButtonClick}
        >
          CLOCK {status?.status === "out" ? "IN" : "OUT"}
        </Button>
        <Dialog fullScreen open={open}>
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                textAlign: "center",
              }}
            >
              Did someone or something prevent you from taking any of your
              mandatory breaks today?
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                fontFamily: "sans-serif",
                textAlign: "center",
                marginTop: 5,
              }}
            >
              If YES, please reach out to the HR Department, so we can address
              the issues promptly.
            </Typography>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              fullWidth
              color="danger"
              size="lg"
              variant="solid"
              onClick={() => handleClose(true)}
            >
              YES
            </Button>
            <Button
              fullWidth
              color="success"
              size="lg"
              variant="solid"
              onClick={() => handleClose(false)}
            >
              NO
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
