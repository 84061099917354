import Status_Entity from "./status";
export default class Status extends Status_Entity {
  static fromList(statussJSON: unknown): Array<Status> {
    const statuss: Status[] = [];
    if (statussJSON)
      Array.isArray(statussJSON) &&
        statussJSON.forEach((statusJSON) => {
          statuss.push(new Status(statusJSON));
        });
    return statuss;
  }

  toJson(): string {
    return JSON.stringify(this);
  }

  getBeginingOfDay() {
    let result!: Date;
    const history = this.history;
    history.forEach((workday) => {
      const punch = new Date(workday.punches[0].clockTime);
      if (!result) {
        result = punch;
      } else if (result.getHours() > punch.getHours()) {
        result = punch;
      }
    });
    return result?.getHours() ?? 0;
  }

  getEndOfDay() {
    let result!: Date;
    const history = this.history;
    history.forEach((workday) => {
      const punch = new Date(
        workday.punches[workday.punches.length - 1].clockTime
      );
      if (!result) {
        result = punch;
      } else if (result.getHours() < punch.getHours()) {
        result = punch;
      }
    });
    return (result?.getHours() ?? 0) + 1;
  }
}
