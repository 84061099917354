import SigninPL_Entity from "./signinPL";
export default class SigninPL extends SigninPL_Entity {
  static fromList(signinPLsJSON: unknown): Array<SigninPL> {
    const signinPLs: SigninPL[] = [];
    if (signinPLsJSON)
      Array.isArray(signinPLsJSON) &&
        signinPLsJSON.forEach((signinPLJSON) => {
          signinPLs.push(new SigninPL(signinPLJSON));
        });
    return signinPLs;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}
