import { Workday } from "../workday";

/**
 * Status_Entity type guard.
 *
 * @param {any} statusJson Status object from API
 * @returns {boolean} Return true if type is Status_Entity
 */
function validator(statusJson: unknown): statusJson is Status_Entity {
  if (typeof statusJson === "object" && statusJson != null) {
    Status_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(statusJson).includes(element))
        throw new Error("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Status_Entity {
  static requiredFields = ["status", "history"];

  constructor(statusJson: unknown) {
    if (validator(statusJson)) {
      this.uuid = statusJson.uuid;
      this.clockPin = statusJson.clockPin;
      this.firstName = statusJson.firstName;
      this.lastName = statusJson.lastName;
      this.status = statusJson.status;
      this.areaID = statusJson.areaID;
      this.message = statusJson.message;
      this.ManagerID = statusJson.ManagerID;
      this.history = statusJson.history;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Status_Entity.name }: ${e}"
      );
    }
  }

  uuid?: string;
  clockPin?: string;
  firstName?: string;
  lastName?: string;
  status: string;
  areaID?: number;
  message?: string;
  ManagerID?: number;
  history: Array<Workday>;
}
