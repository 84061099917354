import {
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { initNetworkAction } from "@nerdjs/nerd-network";
import { clearNotification, NerdNotifier } from "@nerdjs/nerd-ui";
import { ReactElement, useEffect } from "react";

import { CssVarsProvider } from "@mui/joy";
import {
  useGetCurrentUserQuery,
  useSignOutMutation,
} from "@nerdjs/account-kit";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { RouterConfig } from "../config/routerConfig";
import { getTheme } from "../config/themeConfig";
import { useAppDispatch, useAppSelector } from "../hooks";
import styles from "./style.module.scss";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const dispatch = useAppDispatch();
  const muiTheme = useTheme();
  const isDownSm = useMediaQuery(muiTheme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const { isSuccess } = useSignOutMutation()[1];

  useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
  }, []);

  const theme = getTheme();
  const { isFetching } = useGetCurrentUserQuery();

  useEffect(() => {
    //If signout success, refresh window to reset redux state
    if (isSuccess) {
      window.location.replace(RouterConfig.root);
    }
  }, [isSuccess]);

  return (
    <CssVarsProvider theme={theme}>
      <NotifierWrapper>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: isDownSm ? "column" : "row",
          }}
        >
          <CssBaseline />
          {isFetching ? (
            <Grid className={styles.circularProgressContainer} container>
              <Grid item>
                <CircularProgress />
              </Grid>
            </Grid>
          ) : (
            <>
              {/* <NerdAppBar {...appBarConfig(theme)} /> */}
              {/* <MainDrawer /> */}
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  display: "flex",
                }}
              >
                <AppRouter />
              </Box>
            </>
          )}
        </Box>
      </NotifierWrapper>
    </CssVarsProvider>
  );
}

const NotifierWrapper = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((s) => s.notification);
  return (
    notification && (
      <NerdNotifier
        {...notification}
        onClose={() => {
          dispatch(clearNotification());
          notification.onClose?.();
        }}
      >
        {children}
      </NerdNotifier>
    )
  );
};
