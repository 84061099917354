/**
 * SigninPL_Entity type guard.
 *
 * @param {any} signinPLJson SigninPL object from API
 * @returns {boolean} Return true if type is SigninPL_Entity
 */
function validator(signinPLJson: unknown): signinPLJson is SigninPL_Entity {
  if (typeof signinPLJson === "object" && signinPLJson != null) {
    SigninPL_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(signinPLJson).includes(element))
        throw new Error(`Field ${element} is null or undefined`);
    });
  }
  return true;
}

export default class SigninPL_Entity {
  static requiredFields = ["id"];

  constructor(signinPLJson: unknown) {
    if (validator(signinPLJson)) {
      this.id = signinPLJson.id;
      this.createdAt = signinPLJson.createdAt;
      this.updatedAt = signinPLJson.updatedAt;
    } else
      throw new Error(
        `Failed to create new instance of ${SigninPL_Entity.name}`
      );
  }

  id?: number;
  createdAt: string;
  updatedAt: string;
}
