import { apiErrorAction } from "@nerdjs/nerd-network";
import { showNotification } from "@nerdjs/nerd-ui";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { resetStatusTimer } from "../constants";
import { stopTimeOut } from "./app/appAction";
import { entryCodeAddNumber, entryCodeClearValue } from "./entryCodeSlice";
import {
  clearStatus,
  getStatusById,
  getStatusByIdSuccess,
} from "./status/statusActions";
import { RootState, store } from "./store";

import { RouterConfig } from "../hooks/config/routerConfig";
import { signInOTPAddNumber, signInOTPClearValue } from "./signInOTPSlice";
import {
  createSigninPL,
  createSigninPLSuccess,
} from "./signinPL/signinPLActions";

let timerId = -1;

export const mainMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    // Do stuff
    console.log(`[mainMiddleware]: mainMiddleware sees action:`, api);
    console.log(action);

    const state = api.getState() as RootState;

    if (action.type == entryCodeAddNumber) {
      const entryCode = state.entryCode;
      if (entryCode.value.length === 3) {
        // call the API
        const charToAdd = action.payload;
        store.dispatch(getStatusById(`${entryCode.value}${charToAdd}`));
      }
    }

    if (action.type == getStatusByIdSuccess) {
      timerId = window.setTimeout(() => {
        store.dispatch(clearStatus());
      }, resetStatusTimer * 1000);
    }

    if (action.type == clearStatus) {
      store.dispatch(entryCodeClearValue());
      if (timerId != -1) {
        clearTimeout(timerId);
      }
    }

    if (action.type == stopTimeOut) {
      clearTimeout(timerId);
    }

    if (action.type == apiErrorAction && action.payload.error.status === 401) {
      window.location.replace(RouterConfig.signIn);
    }

    if (action.type == signInOTPAddNumber) {
      const signInOTP = state.signInOTP;
      if (signInOTP.value.length === 5) {
        // call the API
        const charToAdd = action.payload;
        const body = {
          code: `${signInOTP.value}${charToAdd}`,
        };
        store.dispatch(createSigninPL(body));
      }
    }

    if (action.type == createSigninPLSuccess) {
      window.location.replace(RouterConfig.clock);
    }

    if (action.type == apiErrorAction) {
      const {
        uuid,
        status,
        description = "",
        message = "",
      } = action.payload.error;

      store.dispatch(
        showNotification({
          notification: {
            uuid: uuid,
            title: `${status} - ${description}`,
            body: message,
            severity: status > 300 && status < 500 ? "warning" : "error",
            autohide: true,
          },
        })
      );

      store.dispatch(entryCodeClearValue());
      store.dispatch(signInOTPClearValue());
    }

    return next(action);
  };
