import { Ship } from "@nerdjs/nerd-network";
import { checkingForNewVersionTimer } from "./constants";

const checkNow = () => {
  const tag = window.app_info?.tag;
  fetch("https://api.arranger.wid.la/arranger/shipServices/551")
    .then((response) => response.json())
    .then((data: Ship) => {
      if (
        tag !== "alpha" &&
        tag !== "_COMMIT_REF_NAME" &&
        tag !== data.currentVersion
      ) {
        window.location.reload();
      }
    });
};

const updatingVersion = () => {
  setInterval(() => {
    checkNow();
  }, checkingForNewVersionTimer * 1000);
};

export default updatingVersion;
