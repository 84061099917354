import { Box } from "@mui/material";
import { NerdCodeField, NerdKeyPad } from "@nerdjs/nerd-ui";
import { useDispatch, useSelector } from "react-redux";
import SignInRequest from "../component/signInRequest";
import { entryCodeSetNewValue } from "../redux/entryCodeSlice";
import {
  signInOTPAddNumber,
  signInOTPClearValue,
  signInOTPDeleteNumber,
} from "../redux/signInOTPSlice";
import { signInOTPSelector } from "../selectors/signInOTPSelector";
import { ButtonType } from "../types";

/**
 *
 * @returns {ReactElement}
 */
export function SignIn() {
  const signInOTP = useSelector(signInOTPSelector);
  const dispatch = useDispatch();

  const handleButtonPressed = (
    selectedNumber: number,
    buttonType: ButtonType
  ) => {
    if (buttonType === "number") {
      dispatch(signInOTPAddNumber(`${selectedNumber}`));
    } else if (buttonType === "clear") {
      {
        dispatch(signInOTPClearValue());
      }
    } else if (buttonType === "delete") {
      dispatch(signInOTPDeleteNumber());
    }
  };

  const keypad = (
    <Box
      sx={{
        width: "250px",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ mb: 2, width: "70%" }}>
        <NerdCodeField
          error={false}
          TextfieldProps={{
            sx: {
              background: "#e2e2e2",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            },
            type: "password",
          }}
          length={6}
          value={signInOTP}
          onChange={(newValue) => dispatch(entryCodeSetNewValue(newValue))}
        ></NerdCodeField>
      </Box>
      <NerdKeyPad handleButtonPressed={handleButtonPressed} />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        height: "100%",
        background: "#fdd835",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <SignInRequest></SignInRequest>
      </Box>
      {keypad}
    </Box>
  );
}
