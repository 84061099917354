import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

export const DateTime = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });
  return (
    <Typography
      variant="h4"
      fontFamily={"sans-serif"}
      sx={{ fontWeight: "bold" }}
    >
      {date.toLocaleTimeString()}
    </Typography>
  );
};

export default DateTime;
