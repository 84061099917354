import { GetAction } from "@nerdjs/nerd-network";
import { createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Status } from "../../entities/status";

export const getStatusById = createAction(
  "service/status/getById",
  function prepare(entryCode: string) {
    const request = new GetAction(`/clock/status/${entryCode}`);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const status = new Status(json);
      dispatch(getStatusByIdSuccess(status));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const getStatusByIdSuccess = createAction(
  "service/status/getStatusByIdSuccess",
  function prepare(status?: Status) {
    return {
      payload: {
        status,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const clearStatus = createAction(
  "service/status/clearStatus",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
