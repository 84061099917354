import { Box, Typography } from "@mui/material";

export default function SignInRequest() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <i style={{ fontSize: 80 }} className="fa-duotone fa-biohazard"></i>
      <Typography
        variant="h4"
        // color={"#d32f2f"}
        fontFamily={"sans-serif"}
        sx={{
          textAlign: "center",
          fontWeight: 800,
        }}
      >
        DO NOT TOUCH
      </Typography>
      <Typography>Please ask your manager to enter his code</Typography>
    </Box>
  );
}
