import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import { Clock } from "./routes/clock";
import NotFoundRoute from "./routes/notFoundRoute";
import { SignIn } from "./routes/signIn";

const BoxWrapper = styled((props: { hideMarginTop?: boolean } & BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  background:
    theme.palette.mode !== "light"
      ? "rgba(0,0,0,0.9)"
      : "rgba(255,255,255,0.9)",
  backdropFilter: "blur(10px)",
  flexGrow: 1,
}));

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  // const { data: currentUser } = useGetCurrentUserQuery();
  return (
    <Routes>
      <Route
        path={RouterConfig.root}
        element={<Navigate to={RouterConfig.clock} />}
      ></Route>
      <Route
        path={RouterConfig.clock}
        element={
          <BoxWrapper>
            <Clock />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={RouterConfig.signIn}
        element={
          <BoxWrapper>
            <SignIn />
          </BoxWrapper>
        }
      ></Route>
      <Route
        path={"*"}
        element={
          <BoxWrapper>
            <NotFoundRoute />
          </BoxWrapper>
        }
      />
    </Routes>
  );
}
