import { createAction, nanoid } from "@reduxjs/toolkit";

export const stopTimeOut = createAction(
  "clock/app/stopTimeOut",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);
