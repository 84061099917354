import { createSlice } from "@reduxjs/toolkit";

export const entryCodeSlice = createSlice({
  name: "entryCode",
  initialState: {
    value: "",
  },
  reducers: {
    entryCodeAddNumber: (state, action) => {
      state.value += action.payload;
    },
    entryCodeDeleteNumber: (state) => {
      state.value = state.value.slice(0, -1);
    },
    entryCodeSetNewValue: (state, action) => {
      state.value = action.payload;
    },
    entryCodeClearValue: (state) => {
      state.value = "";
    },
  },
});

export const {
  entryCodeAddNumber,
  entryCodeDeleteNumber,
  entryCodeSetNewValue,
  entryCodeClearValue,
} = entryCodeSlice.actions;

export const entryCodeReducer = entryCodeSlice.reducer;
