import { PostAction } from "@nerdjs/nerd-network";
import { createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Punch } from "../../entities/punch";
import { Status } from "../../entities/status";
import { getStatusByIdSuccess } from "../status/statusActions";

export const createPunch = createAction(
  "clock/punches/createPunch",
  function prepare(body: Partial<Punch>) {
    const request = new PostAction(`/clock/punches`, body);

    request.decodeResponse = (dispatch: Dispatch, json: Status) => {
      dispatch(getStatusByIdSuccess(json));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);
